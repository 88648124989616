import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { variants } from "../../constants/variants"
import { NAV_ITEMS } from "../../fixtures/NavigationData"
import { FOOTER_SOCIALS } from "../../fixtures/SocialsData"
import Icon from "../Icon/Icon"
import Logo from "../Logo/Logo"
import { logo } from "../../constants/logo"
import "./Footer.scss"

function Footer({ variant }) {
  const { t } = useTranslation()
  const isPlus = variant === variants.plus

  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="container">
          <div className="footer__inner">
            <div className="footer__brand">
              <Logo color={isPlus ? logo.plus : logo.default} />
            </div>

            <div className="footer__nav">
              <ul className="footer__nav--list">
                <li>
                  <h4 className="footer--title">
                    {isPlus ? t("footer.plusTitle") : t("footer.title")}
                  </h4>
                </li>
                {NAV_ITEMS(t).map(({ title, to }) => (
                  <li key={title}>
                    <Link to={to} title={title}>
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
              <ul className="footer__nav--list">
                <li>
                  <h4 className="footer--title">{t("footer.useFulLinks")}</h4>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://kortingscodes.nieuwsblad.be/?utm_source=nostalgie&utm_medium=footerlink&utm_campaign=crosspromo"
                  >
                    {t("footer.discount")}
                  </a>
                </li>
                <li>
                  <Link to={"/contact"}>{t("footer.contact")}</Link>
                </li>
                <li>
                  <Link to={"/adverteren"}>{t("footer.advertise")}</Link>
                </li>
                <li>
                  <Link to={"/vacatures"}>{t("footer.vacancies")}</Link>
                </li>
                <li>
                  <Link to={"/veelgestelde-vragen"}>{t("footer.faq")}</Link>
                </li>
                <li>
                  <Link to={"/wedstrijdreglement"}>
                    {t("footer.competitionRules")}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footer__socials">
              <div>
                <h4 className="footer--title">{t("footer.socialTitle")}</h4>
                <ul className="footer__socials--list">
                  {FOOTER_SOCIALS.map(({ url, icon, title }) => (
                    <li key={title}>
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        title={title}
                      >
                        <Icon name={icon} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer__legal">
        <div className="container">
          <div className="footer__legal--links">
            <a
              href={"https://www.mediahuis.be/nl/voorwaarden/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.generalConditions")}
            </a>
            <a
              href={"http://www.mediahuis.be/privacy-policy"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.privacy")}
            </a>
            <Link to={"/gdpr"}>{t("footer.gdpr")}</Link>
            <a
              href={"https://www.nostalgie.be/?lang=fr"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("footer.frenchSite")}
            </a>
          </div>
        </div>
      </div>

      <div className="footer__contact">
        <div className="container">
          <p>Katwilgweg 2, 2050 Antwerpen - BE 0890.243.036</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
