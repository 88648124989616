export const NAV_ITEMS = t => [
  {
    to: "/acties",
    title: t("headerNav.actions"),
  },
  {
    to: "/programmas",
    title: t("headerNav.programs"),
  },
  {
    to: "/hoe-luisteren",
    title: t("headerNav.howToStream"),
  },
  {
    to: "/top-lijsten",
    title: t("headerNav.topLists"),
  },
  {
    to: "/podcasts",
    title: t("headerNav.podcasts"),
  },
  {
    to: "/nostalgie-plus",
    title: t("headerNav.nostalgiePlus"),
  },
]
