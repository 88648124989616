import cx from "classnames"
import { Link, navigate } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { breakpoints } from "../../constants/breakpoints"
import { logo } from "../../constants/logo"
import { variants } from "../../constants/variants"
import { useOAuthTokens } from "../../hooks/useOAuthTokens"
import { useWindowSize } from "../../hooks/useWindowSize"
import { profileRoute } from "../../routes/routes"
import { NAV_ITEMS } from "../../fixtures/NavigationData"
import Icon from "../Icon/Icon"
import Logo from "../Logo/Logo"
import SearchBar from "../SearchBar/SearchBar"
import "./Nav.scss"


const Nav = ({ variant = "", onPressLogin, onPressSearch, theme = "dark" }) => {
  const { t } = useTranslation()
  const { accessToken } = useOAuthTokens()
  const { width } = useWindowSize()

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const isPlus = useMemo(() => variant === variants.plus, [variant])
  const isDesktop = useMemo(() => width >= breakpoints.xlarge, [width])
  const logoColor = useMemo(() => {
    const isLightTheme = theme === "light"

    if (isPlus) {
      return logo.plus
    }

    if (isMobileNavOpen) {
      return logo.default
    }

    return isLightTheme ? logo.neutral : logo.default
  }, [isMobileNavOpen, isPlus, theme])

  useEffect(() => {
    if (isDesktop) {
      setIsMobileNavOpen(false);
    }
  }, [isDesktop]);

  const onClickProfileLink = () => {
    accessToken ? navigate(profileRoute) : onPressLogin()
  }

  const onClickToggleMenu = () => {
    setIsMobileNavOpen(prevIsOpen => !prevIsOpen)
  }

  const onClickToggleSearch = () => {
    setIsSearchOpen(prevIsOpen => !prevIsOpen)
    onPressSearch()
  }

  return (
    <div
      className={cx(`nav nav--${theme}`, {
        container: isDesktop,
        "nav--is-open": isMobileNavOpen,
      })}
    >
      <button
        onClick={onClickToggleMenu}
        className="nav__button nav__toggle nav__toggle-menu"
        aria-expanded={isMobileNavOpen}
      >
        <span className="u-visually-hidden">Menu</span>
        <span className="nav__hamburger" aria-hidden="true"></span>
      </button>

      <div className="nav__logo">
        <Link to="/" className="nav__link">
          <Logo color={logoColor} />
        </Link>
      </div>
      <nav className="nav__primary">
        <ul className="nav__items">
          {NAV_ITEMS(t).map(({ title, to }) => (
            <li key={title} className="nav__item">
              <Link
                to={to}
                title={title}
                className="nav__link"
                activeClassName="nav__link--active"
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <nav className="nav__secondary">
        <ul className="nav__items">
          <li className="nav__item">
            <button className="nav__button" onClick={onClickProfileLink}>
              <Icon name="user" className="nav__button-icon" />
              <span>
                {accessToken
                  ? t("headerNav.Profile.myProfile")
                  : t("headerNav.Profile.login")}
              </span>
            </button>
          </li>
          {isDesktop && (
            <li className="nav__item nav__item-searchbar">
              <SearchBar className="nav__searchbar" theme={theme} />
            </li>
          )}
        </ul>
      </nav>

      <button
        onClick={onClickToggleSearch}
        className="nav__button nav__toggle nav__toggle-search"
        aria-expanded={isSearchOpen}
      >
        <span className="u-visually-hidden">Search</span>
        <Icon className="nav__button-icon" name="magnifier" />
      </button>
    </div>
  )
}

export default Nav
